import styled from 'styled-components';
import { media } from '../../styles/theme';

export const Wrapper = styled.img<{
  width?: string;
  height?: string;
  loaded: boolean;
}>`
  ${props => `width: ${props.width}`};
  ${props => `height: ${props.height};`}
  object-fit: cover;
  transition: filter 1s ease;
  filter: ${props => (!props.loaded ? `blur(3px)` : `unset`)};
`;

export const BackgroundWrapper = styled.div<{
  width?: string;
  height?: string;
  onClick?: () => void;
  mobileHeight?: string;
  loaded: boolean;
  src: string;
}>`
  ${props => `width: ${props.width}`};
  ${props => `height: ${props.mobileHeight}`};
  transition: filter 1s ease;
  position: relative;
  filter: ${props => (!props.loaded ? `blur(3px)` : `unset`)};
  background: url(${props => props.src});
  background-size: cover;
  display: flex;
  ${media.desktop`
    ${props => `height: ${props.height}`};
  `}
  ${props =>
    props.onClick &&
    `&:hover {
      cursor: pointer;
    }`}
`;

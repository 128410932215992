import styled from 'styled-components';
import { fontSizes, spacing, dimensions, media } from '../../styles/theme';
import LeftArrowSvg from '../../assets/svgs/left-arrow.svg';
import RightArrowSvg from '../../assets/svgs/right-arrow.svg';

export const Wrapper = styled.section`
  display: none;
  position: relative;
  width: 100%;
  padding: ${spacing.thirty};
  ${media.ipadPro`
    display: block;
  `}
`;

export const ImageWrapper = styled.div`
  position: sticky;
  position: -webkit-sticky;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  max-width: 255px;
  ${media.scroll`
    max-width: 300px;
  `}
`;

export const TextWrapper = styled.div`
  position: relative;
  width: 100%;
  height: ${dimensions.minRowHeight};
`;

export const TextContent = styled.div<{ isRight?: boolean }>`
  position: absolute;
  max-width: 300px;
  left: 46%;
  transform: translateX(-173%);
  ${props => props.isRight && `transform: translateX(93%);`}
  ${media.scroll`
    max-width: 350px;
  `}
`;

export const TitleWrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 51.5%;
  transform: translateX(-157%);
`;

export const Title = styled.h1<{ isSticky: boolean; top: number }>`
  ${props =>
    props.isSticky
      ? `position: sticky; position: -webkit-sticky; top: 11%;`
      : `position: relative; top: ${props.top}px;`}
  left: 0;
  font-size: ${fontSizes.sixty};
  margin-left: 90px;
  line-height: 100%;
  ${media.scroll`
    font-size: ${fontSizes.title};
    margin-left: 0;
  `}
`;

export const Subtitle = styled.h3`
  font-size: ${fontSizes.scrollHeading};
  font-family: 'GilroySemiBold';
  line-height: 130%;
  margin-bottom: ${spacing.ten};
`;

export const Description = styled.p`
  font-size: ${fontSizes.normal};
  font-family: 'Bressay';
  line-height: 140%;
`;

export const LeftArrow = styled(LeftArrowSvg)`
  position: absolute;
  left: -70px;
  top: -85px;
  ${media.scroll`
    left: -100px;
  `}
`;

export const RightArrow = styled(RightArrowSvg)`
  position: absolute;
  right: -60px;
  top: -85px;
  ${media.scroll`
    right: -80px;
  `}
`;

export const RightFlippedArrow = styled(RightArrow)`
  transform: rotateX(180deg);
  top: 220px;
`;

export const LeftFlippedArrow = styled(LeftArrow)`
  transform: rotateX(180deg);
  top: 180px;
`;

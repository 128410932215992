import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  Content,
  Title,
  Description,
  Bold,
  TextWrapper,
} from './styles';
import LoadableImage from '../../components/loadableImage';
import { isElementWithinViewport } from '../../services/documentHelpers';
import { ScreenData } from '../../services/prismicConverters';

export type HomeRowProps = {
  data: ScreenData;
  id: string;
};

const HomeRow: React.FC<HomeRowProps> = ({ data, id }) => {
  const [isEleVisible, setIsEleVisible] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const ele = document.getElementById(id);
    if (ele) {
      const isVisible = isElementWithinViewport(ele, 90);
      isVisible && setIsEleVisible(isVisible);
    }
  };

  const maxWidth = data.title ? true : false;

  return (
    <Wrapper id={id}>
      <LoadableImage src={data.image} isBackground>
        <Content isAnimated={isEleVisible}>
          <TextWrapper isAnimated={isEleVisible} maxWidth={maxWidth}>
            {data.title ? (
              <>
                <Title>
                  <Bold>{data.title}</Bold>
                </Title>
                <Description>{data.description}</Description>
              </>
            ) : (
              <>
                <Title>
                  Do Money Better:
                  <br />
                  <Bold>Split. Set. Track.</Bold>
                </Title>
                <Description>
                  <Bold bressay>Split: </Bold>Open a Bó account for your
                  everyday spending money
                  <br />
                  <Bold bressay>Set: </Bold>Create a Spending Budget and Bó goal
                  in the app
                  <br />
                  <Bold bressay>Track: </Bold>Log in to Bó daily to spend less
                  and save more
                </Description>
              </>
            )}
          </TextWrapper>
        </Content>
      </LoadableImage>
    </Wrapper>
  );
};

export default HomeRow;

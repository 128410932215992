import React from 'react';
import { Wrapper, AppStore, GooglePlay } from './styles';
import {
  EVENT_LABELS,
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
} from '../../const/analyticsEvents';
import { TrackedLink } from '../trackedLink';

export type DownloadButtonProps = {
  label_appstore: EVENT_LABELS;
  label_playstore: EVENT_LABELS;
};

const DownloadButtons: React.FC<DownloadButtonProps> = ({
  label_appstore,
  label_playstore,
}) => {
  return (
    <Wrapper>
      <TrackedLink
        link="https://apps.apple.com/gb/app/bo/id1453695899"
        category={EVENT_CATEGORIES.DOWNLOAD_APP}
        action={EVENT_ACTIONS.BUTTON_CLICK}
        label={label_appstore}
        newWindow
      >
        <AppStore />
      </TrackedLink>
      <TrackedLink
        link="https://play.google.com/store/apps/details?id=money.bo.prod"
        category={EVENT_CATEGORIES.DOWNLOAD_APP}
        action={EVENT_ACTIONS.BUTTON_CLICK}
        label={label_playstore}
        newWindow
      >
        <GooglePlay />
      </TrackedLink>
    </Wrapper>
  );
};

export default DownloadButtons;

import styled from 'styled-components';
import { colors, fontSizes, spacing, media } from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  background-color: ${colors.brandYellow};
  padding: ${spacing.sixty} ${spacing.base} ${spacing.sixty} ${spacing.base};
  ${media.phone`
    padding-left: ${spacing.double};
    padding-right: ${spacing.double};
  `}
`;

export const Content = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  font-family: 'GilroyBold';
  margin-bottom: ${spacing.ten};
  line-height: 130%;
  text-align: center;
  ${media.se`
    font-size: ${fontSizes.subHeading};
  `}
  ${media.tablet`
    font-size: ${fontSizes.subtitle};
  `}
  ${media.desktop`
    font-size: ${fontSizes.sixty};
  `}
`;

export const Subtitle = styled.p`
  line-height: 150%;
  font-size: ${fontSizes.subHeading};
  text-align: center;
  font-family: 'Bressay';
  margin-bottom: ${spacing.thirty};
  ${media.se`
    font-size: ${fontSizes.mobileText};
  `}
  ${media.tablet`
    font-size: ${fontSizes.headerText};
  `}
  ${media.desktop`
    font-size: ${fontSizes.subHeading};
    margin-bottom: ${spacing.forty};
  `}
`;

export const TinyText = styled.p`
  margin-top: ${spacing.forty};
  font-size: ${fontSizes.small};
  text-align: center;
`;

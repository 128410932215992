import styled from 'styled-components';
import { fontSizes, spacing, media } from '../../styles/theme';
import NatwestSvg from '../../assets/svgs/natwest.svg';
import LogoSvg from '../../assets/svgs/fscs.svg';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 50vh;
  padding: ${spacing.sixty} ${spacing.base} ${spacing.sixty} ${spacing.base};
  ${media.phone`
    padding-left: ${spacing.double};
    padding-right: ${spacing.double};
  `}
`;

export const Content = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SvgWrapper = styled.div`
  margin-top: ${spacing.twenty};
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h3`
  font-family: 'GilroyBold';
  margin-bottom: ${spacing.ten};
  line-height: 130%;
  text-align: center;
  ${media.se`
    font-size: ${fontSizes.subHeading};
  `}
  ${media.tablet`
    font-size: ${fontSizes.subtitle};
  `}
  ${media.desktop`
    font-size: ${fontSizes.sixty};
  `}
`;

export const Subtitle = styled.p`
  line-height: 150%;
  font-size: ${fontSizes.subHeading};
  font-family: 'Bressay';
  text-align: center;
  margin-bottom: ${spacing.ten};
  ${media.se`
    font-size: ${fontSizes.mobileText};
  `}
  ${media.tablet`
    font-size: ${fontSizes.headerText};
  `}
  ${media.desktop`
    font-size: ${fontSizes.subHeading};
  `}
`;

export const Natwest = styled(NatwestSvg)`
  margin-top: ${spacing.twenty};
  width: 80px;
  height: 80px;
  ${media.desktop`
    width: 140px;
    height: 140px;
  `}
`;

export const Logo = styled(LogoSvg)`
  margin-top: ${spacing.forty};
  margin-right: ${spacing.ten};
  width: 55px;
  height: 55px;
  ${media.desktop`
    margin-top: ${spacing.twenty}; 
    width: 90px;
    height: 90px;
  `}
`;

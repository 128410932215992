import styled from 'styled-components';
import { fontSizes, spacing, media } from '../../styles/theme';

export const Wrapper = styled.section`
  width: 100%;
  ${media.desktop`
    display: none;
  `};
`;

export const TextWrapper = styled.div`
  padding: ${spacing.base};
  ${media.tablet`
    padding: ${spacing.forty};
  `};
`;

export const Title = styled.h3`
  font-family: 'GilroyBold';
  line-height: 130%;
  font-size: ${fontSizes.subHeading};
  margin-bottom: ${spacing.ten};
  ${media.tablet`
    font-size: ${fontSizes.subtitle};
  `};
`;

export const Description = styled.p`
  line-height: 150%;
  font-size: ${fontSizes.mobileText};
  margin-top: 0;
  ${media.tablet`
    font-size: ${fontSizes.subHeading};
  `};
`;

export enum ScreenTypes {
  image = 'image',
  title = 'heading2',
  description = 'paragraph',
}

type Raw = {
  text: string;
  url: string;
  type: ScreenTypes;
};

export const rawInitialState: Raw[] = [
  {
    text: 'text',
    url: 'url.com',
    type: ScreenTypes.description,
  },
  {
    text: 'title',
    url: 'url.com',
    type: ScreenTypes.title,
  },
  {
    text: 'text',
    url: 'url.com',
    type: ScreenTypes.image,
  },
];

export type ScreenData = {
  image: string;
  title?: string;
  description?: string;
};

export const screenDataInitialState = {
  image: 'url.com',
  title: 'title',
  description: 'description',
};

export const getScreenData = (rawData: Raw[]): ScreenData => {
  let screen = {} as ScreenData;
  rawData.find((ele): any => {
    if (ele.type === ScreenTypes.image) {
      screen.image = ele.url;
    }
    if (ele.type === ScreenTypes.title) {
      screen.title = ele.text;
    }
    if (ele.type === ScreenTypes.description) {
      screen.description = ele.text;
    }
  });
  return screen;
};

export enum EVENT_CATEGORIES {
  DOWNLOAD_APP = 'appdownloadcta',
}

export enum EVENT_ACTIONS {
  BUTTON_CLICK = 'buttonclick',
  LINK_CLICK = 'linkclick',
}

// FORMAT for labels: page_location_store_action_device?
export enum EVENT_LABELS {
  homepage_hero_appstore_download = 'homepage_hero_appstore_download',
  homepage_hero_playstore_download = 'homepage_hero_playstore_download',
  blog_articleend_appstore_download = 'blog_articleend_appstore_download',
  blog_articleend_playstore_download = 'blog_articleend_playstore_download',
  homepage_footer_appstore_download = 'homepage_footer_appstore_download',
  homepage_footer_playstore_download = 'homepage_footer_playstore_download',
  homepage_hero_allstores_download_mobile = 'homepage_hero_allstores_download_mobile',
  allpages_stickybutton_download_mobile = 'allpages_stickybutton_download_mobile',
}

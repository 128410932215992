import React from 'react';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import HomeRow from '../../components/homeRow';
import HomeHero from '../../components/homeHero';
import HomeScroll from '../../components/homeScroll';
import HomeMobile from '../../components/homeMobile';
import HomeStatic from '../../components/homeStatic';
import HomeFooterSignUp from '../../components/homeFooterSignUp';
import HomeMobileRow from '../../components/homeMobileRow';
import { titles, descriptions } from '../../const/metaData';
import { Content } from '../../styles/commonStyles';
import { graphql } from 'gatsby';
import { getScreenData } from '../../services/prismicConverters';

export type LandingPageProps = {
  data: any;
};

const LandingPage = ({
  data: {
    prismicLandingPage: { data },
  },
}: LandingPageProps) => {
  const screenData = [
    getScreenData(data.screen_1.raw),
    getScreenData(data.screen_2.raw),
    getScreenData(data.screen_3.raw),
    getScreenData(data.screen_4.raw),
    getScreenData(data.screen_5.raw),
  ];

  return (
    <Layout>
      <SEO title={titles.home} description={descriptions.home} />
      <Content>
        <HomeHero data={getScreenData(data.hero.raw)} />
        <HomeRow data={getScreenData(data.second.raw)} id="skint" />
        <HomeMobileRow data={getScreenData(data.second.raw)} />
        <HomeScroll data={screenData} />
        <HomeMobile data={screenData} />
        <HomeRow data={getScreenData(data.third.raw)} id="transfer" />
        <HomeMobileRow data={getScreenData(data.third.raw)} />
        <HomeStatic />
        <HomeFooterSignUp />
      </Content>
    </Layout>
  );
};

export const landingPageQuery = graphql`
  query TemplateQuery($uid: String!) {
    prismicLandingPage(uid: { eq: $uid }) {
      data {
        hero {
          raw
        }
        second {
          raw
        }
        screen_1 {
          raw
        }
        screen_2 {
          raw
        }
        screen_3 {
          raw
        }
        screen_4 {
          raw
        }
        screen_5 {
          raw
        }
        third {
          raw
        }
      }
    }
  }
`;

export default LandingPage;

import React from 'react';
import {
  Wrapper,
  Section,
  ImageWrapper,
  TextWrapper,
  Title,
  Heading,
  Underline,
  Description,
} from './styles';
import LoadableImage from '../loadableImage';
import { ScreenData } from '../../services/prismicConverters';

export type HomeMobileProps = {
  data: ScreenData[];
};

const HomeMobile: React.FC<HomeMobileProps> = (props) => {
  const { data } = props;

  return (
    <Wrapper>
      <Heading>You can use Bó to:</Heading>
      <Underline />
      <Section>
        <ImageWrapper>
          <LoadableImage src={data[0].image} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{data[0].title}</Title>
          <Description>{data[0].description}</Description>
        </TextWrapper>
      </Section>
      <Section>
        <ImageWrapper>
          <LoadableImage src={data[1].image} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{data[1].title}</Title>
          <Description>{data[1].description}</Description>
        </TextWrapper>
      </Section>
      <Section>
        <ImageWrapper>
          <LoadableImage src={data[2].image} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{data[2].title}</Title>
          <Description>{data[2].description}</Description>
        </TextWrapper>
      </Section>
      <Section>
        <ImageWrapper>
          <LoadableImage src={data[3].image} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{data[3].title}</Title>
          <Description>{data[3].description}</Description>
        </TextWrapper>
      </Section>
      <Section>
        <ImageWrapper>
          <LoadableImage src={data[4].image} />
        </ImageWrapper>
        <TextWrapper>
          <Title>{data[4].title}</Title>
          <Description>{data[4].description}</Description>
        </TextWrapper>
      </Section>
    </Wrapper>
  );
};

export default HomeMobile;

import React from 'react';
import { Wrapper, Title, Description, TextWrapper } from './styles';
import LoadableImage from '../../components/loadableImage';
import { ScreenData } from '../../services/prismicConverters';

export type HomeMobileRowProps = {
  data: ScreenData;
};

const HomeMobileRow: React.FC<HomeMobileRowProps> = ({ data }) => {
  return (
    <Wrapper>
      <LoadableImage src={data.image} />
      <TextWrapper>
        {data.title ? (
          <>
            <Title>{data.title}</Title>
            <Description>{data.description}</Description>
          </>
        ) : (
          <>
            <Title>
              Do Money Better:
              <br />
              Split, Set, Track.
            </Title>
            <Description>
              <b>Split: </b>Open a Bó account for your everyday spending money
              <br />
              <b>Set: </b>Create a Spending budget and Bó Goal in the app
              <br />
              <b>Track: </b>Log in to Bó daily to spend less and save more
            </Description>
          </>
        )}
      </TextWrapper>
    </Wrapper>
  );
};

export default HomeMobileRow;

import React from 'react';
import {
  Wrapper,
  Title,
  Subtitle,
  SvgWrapper,
  Natwest,
  Logo,
  Content,
} from './styles';
import { Link } from 'gatsby';
import { paths } from '../../const/paths';

const HomeStatic = () => {
  return (
    <Wrapper>
      <Content>
        <Title>We're Part of NatWest</Title>
        <Subtitle>
          Your money and your data are safe, secure and protected. Bó is covered
          by the Financial Services Compensation{' '}
          <Link to={paths.fscs}>Scheme</Link>*.
        </Subtitle>
        <SvgWrapper>
          <Natwest />
          <Logo />
        </SvgWrapper>
      </Content>
    </Wrapper>
  );
};

export default HomeStatic;

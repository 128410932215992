import styled from 'styled-components';
import { fontSizes, spacing, media, colors } from '../../styles/theme';
import QRCodeImage from '../../assets/images/QRCode.png';
import QRTextImage from '../../assets/images/QRText.png';

export const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 61vh;
  width: 100%;
  align-items: center;
  background-color: ${colors.brandYellow};
  padding-left: 0;
  padding-right: 0;
  ${media.ipadPro`
    flex-direction: row;
    padding-left: 10vw;
    padding-right: 10vw; 
    height: 100vh; 
  `};
  z-index: 1;
  overflow: hidden;
`;

export const Title = styled.h1`
  font-size: ${fontSizes.title};
  margin-top: 0;
  color: ${colors.brandYellow};
  margin-bottom: 0;
  ${media.ipadPro`
    font-size: ${fontSizes.gigantic};
  `}
`;

export const Content = styled.div`
  display: none;
  ${media.ipadPro`
    display: block;
  `};
`;

export const QRWrapper = styled.div`
  display: none;
  height: 11vw;
  max-height: 200px;
  min-height: 140px;
  ${media.ipadPro`
    display: flex;  
  `};
`;

export const QRCode = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 128px;
  flex: 1;
  flex-shrink: 0;
  background: url(${QRCodeImage}) no-repeat left center;
  text-indent: -9999px;
  white-space: nowrap;
  background-size: contain;
`;

export const QRWrapperText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  margin-top: -6px;
  max-width: 350px;
  background: url(${QRTextImage}) no-repeat center center;
  background-size: contain;
  margin-left: 51px;
  text-indent: -9999px;
  white-space: nowrap;
`;

export const QRText = styled.p`
  margin: 0;
  padding: 0;
`;

export const StickyButtonWrapper = styled.div`
  display: block;
  ${media.ipadPro`
    display: none;
  `};
`;

export const Button = styled.div`
  display: flex;
  background-color: ${colors.brandYellow};
  text-decoration: none;
  align-items: center;
  justify-content: center;
  font-family: 'GilroySemiBold';
  min-height: 50px;
  font-size: ${fontSizes.mobileText};
  width: 100%;
  border-radius: 10px;
  margin-top: ${spacing.half};
  ${media.ipadPro`
    display: none;
  `};
`;

export const Subtitle = styled.h3`
  margin-top: ${spacing.twenty};
  margin-bottom: ${spacing.twenty};
  font-family: 'BressayBoldItalic';
  font-size: ${fontSizes.subHeading};
  line-height: 110%;
  ${media.ipadPro`
    font-size: ${fontSizes.large};
  `};
`;

export const Text = styled.h3`
  margin-top: ${spacing.ten};
  margin-bottom: ${spacing.ten};
  font-family: 'GilroySemiBold';
  font-size: ${fontSizes.link};
  ${media.ipadPro`
    font-size: ${fontSizes.large};
  `};
`;

export const TinyText = styled.p`
  font-size: ${fontSizes.small};
  margin-top: ${spacing.thirty};
  width: 100%;
  ${media.ipadPro`
    width: 80%;
  `};
`;

export const HandWrapper = styled.div<{ isHand: boolean }>`
  width: 50%;
  height: 100%;
  display: none;
  margin-left: 100px;
  transition: all 0.25s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  transform: translate(50%, 50%) scale(1.2) rotate(-20deg);
  ${props =>
    props.isHand && `transform: translate(0%, 20%) scale(1) rotate(0deg);`}
  ${media.ipadPro`
    display: block;
  `};
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-self: center;
  background-color: transparent;
  z-index: 1;
  max-width: 700px;
  padding: ${spacing.base};
  ${media.ipadPro`
    width: 50%;
    align-self: flex-end;
  `};
`;

export const Circle = styled.div<{ isYellow: boolean }>`
  position: absolute;
  height: 50px;
  width: 50px;
  border-radius: 25px;
  transform: translate(-50%, -200%)
    scale(${props => (props.isYellow ? `80` : `0`)});
  background-color: ${colors.white};
  transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  top: 50%;
  left: 50%;
  ${media.ipadPro`
    transform: translate(400%, -300%)
      scale(${props => (props.isYellow ? `80` : `0`)});
  `};
`;

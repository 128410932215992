import React from 'react';
import DownloadButtons from '../../atoms/downloadButtons';
import { Wrapper, Title, Subtitle, Content } from './styles';
import { TinyText } from './styles';
import { messages } from '../../const/messages';
import { EVENT_LABELS } from '../../const/analyticsEvents';

const HomeFooterSignUp = () => (
  <Wrapper>
    <Content>
      <Title>Join Bó in minutes</Title>
      <Subtitle>
        Open an account straight from your phone! There's no need to switch
        current accounts (leave your bills and direct debits where they are) and
        no monthly fees.
      </Subtitle>
      <DownloadButtons
        label_appstore={EVENT_LABELS.homepage_footer_appstore_download}
        label_playstore={EVENT_LABELS.homepage_footer_playstore_download}
      />
      <TinyText>{messages.terms}</TinyText>
    </Content>
  </Wrapper>
);

export default HomeFooterSignUp;

import React from 'react';
import styled from 'styled-components';
import Analytics from '../../utils/analytics';
import {
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
} from '../../const/analyticsEvents';

const StyledTrackedLink = styled.a`
  text-decoration: none;
`;

export type TrackedLinkProps = {
  category: EVENT_CATEGORIES;
  action: EVENT_ACTIONS;
  label: EVENT_LABELS;
  link: string;
  children: React.ReactNode;
  newWindow?: boolean;
};

export const TrackedLink: React.FC<TrackedLinkProps> = ({
  category,
  action,
  link,
  label,
  children,
  newWindow,
}) => {
  return (
    <StyledTrackedLink
      onClick={(event: React.BaseSyntheticEvent) =>
        Analytics.trackLinkEvent(event, category, action, label, newWindow)
      }
      href={link}
      data-label={label}
    >
      {children}
    </StyledTrackedLink>
  );
};

import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  Title,
  TinyText,
  Subtitle,
  HandWrapper,
  TextWrapper,
  Text,
  Circle,
  Content,
  QRWrapper,
  QRCode,
  QRWrapperText,
  QRText,
} from './styles';
import LoadableImage from '../loadableImage';
import DownloadButtons from '../../atoms/downloadButtons';
import { messages } from '../../const/messages';
import { ScreenData } from '../../services/prismicConverters';
import { EVENT_LABELS } from '../../const/analyticsEvents';

export type HomeHeroProps = {
  data: ScreenData;
};

const HomeHero = ({ data }: HomeHeroProps) => {
  const [isHand, setIsHand] = useState(false);
  const [isYellow, setIsYellow] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (isLoaded) {
      setIsHand(true);
      setTimeout(() => {
        setIsYellow(true);
      }, 250);
    }
  });

  return (
    <Wrapper>
      <Circle isYellow={isYellow} />
      <TextWrapper>
        <Title>{data.title}</Title>
        <Subtitle>{data.description}</Subtitle>
        <QRWrapper>
          <QRCode />
          <QRWrapperText>
            <QRText>
              Open your phone camera to scan this QR code and download Bó now!
            </QRText>
          </QRWrapperText>
        </QRWrapper>
        <Content>
          <Text>Download now. It's free!</Text>
          <DownloadButtons
            label_appstore={EVENT_LABELS.homepage_hero_appstore_download}
            label_playstore={EVENT_LABELS.homepage_hero_playstore_download}
          />
        </Content>
        <TinyText>{messages.terms}</TinyText>
      </TextWrapper>
      <HandWrapper isHand={isHand}>
        <LoadableImage src={data.image} isComplete={() => setIsLoaded(true)} />
      </HandWrapper>
    </Wrapper>
  );
};

export default HomeHero;

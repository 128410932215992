import styled from 'styled-components';
import AppStoreSvg from '../../assets/svgs/app-store.svg';
import GooglePlaySvg from '../../assets/svgs/google-play.svg';
import { spacing } from '../../styles/theme';

export const Wrapper = styled.div`
  display: flex;
`;

export const AppStore = styled(AppStoreSvg)`
  &:hover {
    cursor: pointer;
  }
`;

export const GooglePlay = styled(GooglePlaySvg)`
  margin-left: ${spacing.forty};
  &:hover {
    cursor: pointer;
  }
`;

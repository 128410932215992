import styled from 'styled-components';
import { colors, media, fontSizes, spacing } from '../../styles/theme';
import UnderlineSvg from '../../assets/svgs/underline.svg';

export const Wrapper = styled.section`
  width: 100%;
  height: 100%;
  display: block;
  margin-bottom: ${spacing.double};
  ${media.ipadPro`
    display: none
  `};
`;

export const Section = styled.div`
  position: relative;
  margin: auto;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: 88vh;
  padding: ${spacing.thirty} ${spacing.forty} 0 ${spacing.forty};
  background-color: ${colors.backgroundGrey};
  ${media.phonePlus`
    height: auto;
  `};
`;

export const TextWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.white};
  padding: ${spacing.base};
  ${media.tablet`
    padding: ${spacing.forty};
  `};
`;

export const Heading = styled.h3`
  font-family: 'GilroySemiBold';
  line-height: 130%;
  padding: ${spacing.eighteen} 0 0 ${spacing.eighteen};
  margin-bottom: -1px;
  font-size: ${fontSizes.subHeading};
  ${media.tablet`
    font-size: ${fontSizes.subtitle};
    padding: ${spacing.double} 0 0 ${spacing.double};
  `};
`;

export const Title = styled.h3`
  font-family: 'GilroySemiBold';
  font-size: ${fontSizes.headerText};
  line-height: 130%;
  ${media.tablet`
    font-size: ${fontSizes.subtitle};
  `};
`;

export const Description = styled.p`
  font-size: ${fontSizes.mobileText};
  line-height: 150%;
  ${media.tablet`
    font-size: ${fontSizes.subHeading};
  `};
`;

export const Underline = styled(UnderlineSvg)`
  margin-left: ${spacing.eighteen};
  margin-bottom: ${spacing.base};
  ${media.tablet`
    margin-left: ${spacing.double};
    margin-bottom: ${spacing.double};
  `};
`;

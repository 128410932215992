import {
  EVENT_CATEGORIES,
  EVENT_ACTIONS,
  EVENT_LABELS,
} from '../../const/analyticsEvents';

/**
 * Used to determine whether analytics are available based on whether mparticle
 * is fully initialised. This happens when the user opts-in to cookies via
 * OneTrust.
 */
const isEnabled = (): boolean =>
  typeof window.mParticle !== 'undefined' &&
  typeof window.mParticle.config !== 'undefined' &&
  typeof window.mParticle.config.appName !== 'undefined';

const Analytics = {
  isEnabled,
  trackLinkEvent: (
    e: React.BaseSyntheticEvent,
    category: EVENT_CATEGORIES,
    action: EVENT_ACTIONS,
    label: EVENT_LABELS,
    newWindow?: boolean
  ) => {
    e.preventDefault();

    // NB: we shouldn't log to analytics if the user hasn't opted-in.
    if (isEnabled()) {
      window.mParticle.logEvent('analytics', window.mParticle.EventType.Other, {
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
      });
    }
    if (newWindow) {
      window.open(e.currentTarget.href);
    } else {
      window.location.href = e.currentTarget.href;
    }
  },
};

export default Analytics;

import React, { useState, useEffect } from 'react';
import { Wrapper, BackgroundWrapper } from './styles';

export type LoadableImageProps = {
  src: string;
  width?: string;
  height?: string;
  mobileHeight?: string;
  alt?: string;
  isBackground?: boolean;
  children?: any;
  isComplete?: () => void;
  onClick?: () => void;
  style?: {};
};

const LoadableImage = (props: LoadableImageProps) => {
  const runOnce = true;
  const [loadState, setLoadState] = useState({ src: '', loaded: false });

  useEffect(() => {
    const { isComplete, src } = props;

    const image = new Image();
    image.onload = () => {
      setLoadState({ src: image.src, loaded: true });
      isComplete && isComplete();
    };
    image.src = src;
  }, [runOnce]);

  if (props.isBackground) {
    return (
      <BackgroundWrapper loaded={loadState.loaded} src={props.src} {...props}>
        {props.children}
      </BackgroundWrapper>
    );
  }

  return (
    <Wrapper
      loaded={loadState.loaded}
      src={props.src}
      {...props}
      alt={props.alt}
    />
  );
};

export default LoadableImage;

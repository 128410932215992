import React, { useEffect, useState } from 'react';
import {
  Wrapper,
  ImageWrapper,
  TextWrapper,
  TextContent,
  TitleWrapper,
  Title,
  Subtitle,
  Description,
} from './styles';
import LoadableImage from '../loadableImage';
import { isElementWithinViewport } from '../../services/documentHelpers';
import { ScreenData } from '../../services/prismicConverters';

export type HomeScrollProps = {
  data: ScreenData[];
};

const HomeScroll: React.FC<HomeScrollProps> = ({ data }) => {
  const [displayImage, setDisplayImage] = useState(data[0].image);
  const [isSticky, setIsSticky] = useState(true);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const title = document.getElementById('title');
    if (title) {
      const titleOffset = title.getBoundingClientRect().top;
      setOffset(10 - titleOffset);
    }
  }, [isSticky]);

  const handleScroll = () => {
    const ele0 = document.getElementById('0');
    if (ele0 && isElementWithinViewport(ele0, 40)) {
      handleTitle(ele0);
      setDisplayImage(data[0].image);
    }

    const ele1 = document.getElementById('1');
    if (ele1 && isElementWithinViewport(ele1, 60)) {
      setDisplayImage(data[1].image);
    }

    const ele2 = document.getElementById('2');
    if (ele2 && isElementWithinViewport(ele2, 40)) {
      setDisplayImage(data[2].image);
    }

    const ele3 = document.getElementById('3');
    if (ele3 && isElementWithinViewport(ele3, 40)) {
      setDisplayImage(data[3].image);
    }

    const ele4 = document.getElementById('4');
    if (ele4 && isElementWithinViewport(ele4, 60)) {
      setDisplayImage(data[4].image);
    }
  };

  const handleTitle = (ele: HTMLElement) => {
    const elementOffset = ele.getBoundingClientRect().top;
    if (elementOffset <= 270) {
      return setIsSticky(false);
    }
    setIsSticky(true);
  };

  return (
    <Wrapper>
      <TitleWrapper id="title">
        <Title isSticky={isSticky} top={offset}>
          You can use
          <br />
          Bó to:
        </Title>
      </TitleWrapper>
      <ImageWrapper>
        <LoadableImage src={displayImage} />
      </ImageWrapper>
      <TextWrapper id="0">
        <TextContent>
          <Subtitle>{data[0].title}</Subtitle>
          <Description>{data[0].description}</Description>
        </TextContent>
      </TextWrapper>
      <TextWrapper id="1">
        <TextContent isRight>
          <Subtitle>{data[1].title}</Subtitle>
          <Description>{data[1].description}</Description>
        </TextContent>
      </TextWrapper>
      <TextWrapper id="2">
        <TextContent>
          <Subtitle>{data[2].title}</Subtitle>
          <Description>{data[2].description}</Description>
        </TextContent>
      </TextWrapper>
      <TextWrapper id="3">
        <TextContent isRight>
          <Subtitle>{data[3].title}</Subtitle>
          <Description>{data[3].description}</Description>
        </TextContent>
      </TextWrapper>
      <TextWrapper id="4">
        <TextContent>
          <Subtitle>{data[4].title}</Subtitle>
          <Description>{data[4].description}</Description>
        </TextContent>
      </TextWrapper>
    </Wrapper>
  );
};

export default HomeScroll;

import styled from 'styled-components';
import {
  colors,
  fontSizes,
  spacing,
  dimensions,
  media
} from '../../styles/theme';

export const Wrapper = styled.section`
  display: none;
  flex-direction: column;
  align-items: center;
  position: relative;
  ${media.desktop`
    display: flex;
  `};
`;

export const Content = styled.div<{ isAnimated: boolean }>`
  display: flex;
  padding: ${spacing.thirty};
  width: 90vw;
  height: ${dimensions.minRowHeight};
  transition: 0.5s ease-in-out;
  ${props => props.isAnimated && `width: 100vw; height: 100vh;`}
  ${media.desktop`
    padding: ${spacing.ninety};
  `};
`;

export const TextWrapper = styled.div<{
  isAnimated: boolean;
  maxWidth?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  max-width: ${props => (props.maxWidth ? `500px;` : '800px;')}
  transition: all 0.5s ease-in-out;
  opacity: ${props => (props.isAnimated ? `1` : `0`)};
  transform: translateY(${props => (props.isAnimated ? '0%' : '20%')});
`;

export const Title = styled.h3`
  color: ${colors.white};
  line-height: 100%;
  font-size: ${fontSizes.larger};
  margin-bottom: 0;
`;

export const Description = styled.p`
  color: ${colors.white};
  line-height: 130%;
  font-size: ${fontSizes.subHeading};
  margin-bottom: ${spacing.thirty};
  font-family: 'Bressay';
`;

export const Bold = styled.b<{ bressay?: boolean }>`
  color: ${colors.white};
  font-family: ${props => (props.bressay ? `BressayBold` : `GilroyBold`)};
`;
